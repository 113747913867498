import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout } from '../components'
import Venue from '../templates/venue'
import { container, padding, bgImage, bgIcon } from '../styles/global';
import { absoluteCover, media } from '../styles/utils';

const Index = (props) => {    
    const data = useStaticQuery(query).allWordpressInfopages.nodes[0]

//     const renderSectionLinks = () => {

//         const links = [
//             {
//                 slug: 'pepes',
//                 image: data.pepes_hero_image.sizes.medium2,
//                 tagline: data.pepes_tagline,
//                 logo: require('../assets/images/pepes-logo.svg')
//             },
//             {
//                 slug: 'trunk-diner',
//                 image: data.trunkdiner_hero_image.sizes.medium2,
//                 tagline: data.trunkdiner_tagline,
//                 logo: require('../assets/images/trunk-diner-logo.svg')
//             },
//         ]
        
//         return links.map((item, i) => {
//             return (
//                 <Section 
//                     key={i}
//                     slug={item.slug}
//                     to={item.slug}
//                 >
//                     <Image
//                         image={item.image}
//                     />

//                     <Logo
//                         image={item.logo}
//                     />

//                     <Heading>
//                         {item.tagline}
//                     </Heading>
//                 </Section>
//             )
//         })
//     }

// 	return (
// 		<Layout
//             meta={data && data.seo}
//             noHeader
//             overSlider
//         >
// 			<Container>
//                 {renderSectionLinks()}
// 			</Container>
// 		</Layout>	
// 	)
// }


// // Shared

// const Heading = styled.h1``
// const Subheading = styled.h2``
// const Description = styled.div``
// const Info = styled.div``

// const Image = styled.div`
//     background-image: url(${props => props.image});  
//     ${bgImage}
// `


// // Layout

// const Container = styled.div`
//     ${container}
//     max-width: none;
//     display: flex;
//     flex: 1;
//     height: 100vh;

//     ${media.tablet`
//         flex-direction: column;
//     `}
// `

// // Section

// const Logo = styled.div`
//     background-image: url(${props => props.image});  
//     ${bgIcon}
// `

// const Section = styled(Link)`
//     height: 100vh;
//     flex: 0 1 50%;
//     position: relative;
//     background: black;
//     cursor: pointer;

//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     ${Logo} {
//         width: 100%;
//         height: 100%;
//     }

//     ${Heading} {
//         font-size: 22px;
//         font-weight: bold;
//         color: white;
//         margin-top: auto;

//         position: absolute;
//         bottom: 56px;
//     }

//     ${Image} {
//         ${absoluteCover}
//         opacity: 0.85;
//         transition: opacity 0.15s ease-in-out;
//     }

//     > *:not(${Image}) {
//         z-index: 2;
//     }

//     /* Logos */

//     ${props => {
//         if (props.slug == 'pepes') return css`
//             padding: 0 63px;
//             ${Logo} {
//                 max-width: 414px;
//                 max-height: 333px;
              
//             }
//         `

//         if (props.slug == 'trunk-diner') return css`
//             padding: 0 75px;

//             ${Logo} {
//                 max-width: 358px;
//                 max-height: 181px;
//             }
//         `
//     }}

//     /* Hover */

//     &:hover {
//         ${Image} {
//             opacity: 0.7;
//         }
//     }

//     ${media.tablet`
//         flex: 0 1 50%;
//         height: 50vh;

//         ${Heading} {
//             bottom: 18px;
//         }
//     `}

//     ${media.phone`
//         ${Heading} {
//             font-size: 16px;
//         }
//     `}
// `

    return <Venue pageContext={{
        title: data.title,
        slug: data.slug,
        acf_json: data.acf_json,
        menu: {
            menuItems: data.subs,
            parent: {
                slug: 'pepes'
            }
        },
    }} />
}

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "pepes" } }) {
            nodes {
                title
                slug
                subs {
                    title
                    acf_json
                    slug
                    wordpress_id
                }
                acf_json
            }
        }
    }
`

export default Index

